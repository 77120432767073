<template>
  <form
    class="w-64 ml-auto mr-auto grid gap-4"
    @submit.prevent="submit"
    id="form_user_forgot_password"
  >
    <FormAlert v-if="alert" :type="alert.type">
      <span v-if="alert.isHtml" v-html="alert.message"></span>
      <span v-else>{{ alert.message }}</span>
    </FormAlert>

    <FormInput
      ref="emailinput"
      :modelValue="email"
      type="email"
      required
      placeholder="Email"
      @update:modelValue="$emit('update:email', $event)"
    />
    <CommonSpinner v-if="loading" />
    <input
      v-else
      type="submit"
      class="cursor-pointer py-3 px-4 text-white article-label !font-medium w-full"
      :value="$t('sendResetLink')"
      :class="
        false
          ? 'rounded-md bg-black transform translate-y-px'
          : 'rounded-full bg-blue hover:bg-denimblue'
      "
    />
  </form>
</template>

<script setup lang="ts">
import type { FetchError } from 'ofetch'
import type { AlertBundle } from '~/typesManual/alert'

const nuxtApp = useNuxtApp()

const { sendPasswordResetEmail } = useEmailFlow()

const props = defineProps<{
  email: string
}>()

const loading = ref(false)
const alert = ref<AlertBundle | undefined>(undefined)

async function submit() {
  loading.value = true
  alert.value = undefined
  const { err } = await sendPasswordResetEmail(props.email)

  if (err) {
    const cause = err.cause as FetchError

    if (cause && cause.statusCode === 404) {
      loading.value = false
      alert.value = {
        type: ALERT_TYPE.ERROR,
        message: nuxtApp.$t('emailNotFound'),
      }
      return
    } else if (cause && cause.statusCode === 403) {
      loading.value = false
      alert.value = {
        type: ALERT_TYPE.ERROR,
        message: nuxtApp.$t('UserAccountNotCreatedYet', {
          link: false ? '/usercreatemm' : '/usercreate',
        }),
        isHtml: true,
      }
      return
    }

    loading.value = false
    alert.value = {
      type: ALERT_TYPE.ERROR,
      message: nuxtApp.$t('errorOccurred'),
    }
    return
  }

  loading.value = false
  alert.value = {
    type: ALERT_TYPE.SUCCESS,
    message: nuxtApp.$t('EmailSent'),
  }
}
</script>
